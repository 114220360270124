<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeCreate() {
    // document.querySelector('html').setAttribute('style', 'background-color:#fffcf5;')
    // document.querySelector('html').style.width = '375px'
    // document.querySelector('body').setAttribute('style', 'background-color:#fffcf5;')
    // document.querySelector('body').style.width = '375px'
  }
}
</script>

<style lang="less">
#app {
  width: 375px;
}
</style>
